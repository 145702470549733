import React, { Suspense, useEffect } from 'react'
import { WrapperContent } from '../style/style'
import { Routes, Route, useLocation } from 'react-router-dom'
import MyLoading from '../modules/MyLoading/MyLoading'
import HeaderBox from '../modules/HeaderBox/HeaderBox'
import AdvertisingItem from '../modules/Advertising/AdvertisingItem'
import PageUp from '../modules/PageUp/PageUp'
import PageSearch from './PageSearch/PageSearch'
import BgAnimation from '../modules/BgAnimation/BgAnimation'
import FooterBox from '../modules/FooterBox/FooterBox'
import Profile from './Profile/Profile'
import RestrictedRoutes from '../modules/RestrictedRoutes/RestrictedRoutes'
import ym from 'react-yandex-metrika'
const Home = React.lazy(() => import('./Home/Home'))
const Boor = React.lazy(() => import('./Boor/Boor'))
const ArchiveNews = React.lazy(() => import('./News/ArchiveNews'))
const Membership = React.lazy(() => import('./Membership/Membership'))
const LegalBasis = React.lazy(() => import('./LegalBasis/LegalBasis'))
const OurEverything = React.lazy(() => import('./OurEverything/OurEverything'))
const HuntingCalendar = React.lazy(() => import('./HuntingCalendar/HuntingCalendar'))
const Cynology = React.lazy(() => import('./Cynology/Cynology'))
const WomansClub = React.lazy(() => import('./WomansClub/WomansClub'))
const Contact = React.lazy(() => import('./Contact/Contact'))
const Hunting = React.lazy(() => import('./Hunting/Hunting'))
const MapsId = React.lazy(() => import('./RegionalFarms/maps/MapsId'))
const Fishing = React.lazy(() => import('./Fishing/Fishing'))
const Tourism = React.lazy(() => import('./Tourism/Tourism'))
const HuntingFarm = React.lazy(() => import('./HuntingFarm/HuntingFarm'))
const RegionalFarms = React.lazy(() => import('./RegionalFarms/RegionalFarms'))
const PageItemNew = React.lazy(() => import('../modules/News/PageItemNew/PageItemNew'))
const PageSearchItem = React.lazy(() => import('./PageSearch/item/PageSearchItem'))
const NotFound = React.lazy(() => import('./NotFound/NotFound'))
const MyTrophies = React.lazy(() => import('./MyTrophies/MyTrophies'))
const UserManagement = React.lazy(() => import('./UserManagement/UserManagement'))
const ApplicationManagement = React.lazy(() => import('./ApplicationManagement/ApplicationManagement'))

const PageNewEmail = React.lazy(() => import('./PageNewEmail/PageNewEmail'))
const TrophyBook = React.lazy(() => import('./TrophyBook/TrophyBook'))
const AddingHuntingGrounds = React.lazy(() => import('./AddingHuntingGrounds/AddingHuntingGrounds'))
const Workshop = React.lazy(() => import('./Workshop/Workshop'))

const IndexPage = () => {
  const location = useLocation()

  useEffect(() => {
    ym('hit', 90583732, location.pathname)
  }, [location])

  return (
    <>
      <BgAnimation />
      <HeaderBox />

      <WrapperContent className="container h-500 relative" id="anchor">
        <Suspense fallback={<MyLoading />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/advertising/:id" element={<AdvertisingItem />} />
            <Route path="/archive-news" element={<ArchiveNews type="common" />} />

            <Route path="/boor" element={<Boor />} />
            <Route path="/boor/:type" element={<Boor />} />
            <Route path="/membership" element={<Membership />} />
            <Route path="/membership/:type" element={<Membership />} />
            <Route path="/legal-basis" element={<LegalBasis />} />
            <Route path="/legal-basis/:type" element={<LegalBasis />} />
            <Route path="/our-everything" element={<OurEverything />} />
            <Route path="/our-everything/:type" element={<OurEverything />} />
            <Route path="/hunting-calendar" element={<HuntingCalendar />} />
            <Route path="/hunting-calendar/:type" element={<HuntingCalendar />} />
            <Route path="/cynology" element={<Cynology />} />
            <Route path="/cynology/:type" element={<Cynology />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/contact/:type" element={<Contact />} />
            <Route path="/hunting" element={<Hunting />} />
            <Route path="/hunting/:type" element={<Hunting />} />
            <Route path="/hunting/:type/:addition" element={<Hunting />} />
            <Route path="/hunting/map/:id" element={<MapsId />} />
            <Route path="/fishing" element={<Fishing />} />
            <Route path="/fishing/:type" element={<Fishing />} />
            <Route path="/tourism" element={<Tourism />} />
            <Route path="/tourism/:type" element={<Tourism />} />
            <Route path="/hunting-farm" element={<HuntingFarm />} />
            <Route path="/hunting-farm" element={<HuntingFarm />} />
            <Route path="/regional-farms/:type" element={<RegionalFarms />} />
            <Route path="/regional-farms/:type/:addition" element={<RegionalFarms />} />
            <Route path="/regional-farms" element={<RegionalFarms />} />
            <Route path="/news/:id" element={<PageItemNew />} />
            <Route path="/search" element={<PageSearch />} />
            <Route path="/search/:id" element={<PageSearchItem />} />
            <Route path="/activation_new_email" element={<PageNewEmail />} />
            <Route path="/confirm_email" element={<PageNewEmail />} />

            <Route path="/trophy-book/*" element={<TrophyBook />} />
            <Route path="/womans-club" element={<WomansClub />} />
            <Route path="/womans-club/:type" element={<WomansClub />} />
            <Route path="/workshop" element={<Workshop />} />
            <Route path="/workshop/:type" element={<Workshop />} />

            <Route path="/cabinet/*" element={<RestrictedRoutes />}>
              <Route path="profile" element={<Profile />} />
              <Route path="my-trophies" element={<MyTrophies />} />
              <Route path="adding-hunting-grounds" element={<AddingHuntingGrounds />} />
              <Route path="user-management" element={<UserManagement />} />
              <Route path="application-management" element={<ApplicationManagement />} />
            </Route>

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </WrapperContent>
      <PageUp />

      <FooterBox />
    </>
  )
}

export default IndexPage
